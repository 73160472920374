import $ from 'jquery'

class AddHtmlAsClass {
    constructor(targetAttr) {
        
        this.elements = {
            targetAttr: $(targetAttr)
        }

        this.elements.targetAttr.each(function() {
            const className = $(this).html();
            $(this).addClass(className);
        });
    }
}

export default AddHtmlAsClass