import $ from 'jquery'

class AddClassMethods {
    constructor(addClassTarget, classNameEl) {
        
        this.elements = {
            addClassTarget: $(addClassTarget), 
            classNameEl: classNameEl
        }

        this.events()
    }

    events() {
        this.elements.addClassTarget.addClass(this.elements.classNameEl);
    }
}

export default AddClassMethods