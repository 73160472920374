import $ from 'jquery'

class ChildContainsClass {
    constructor($targetEl, classNameEl) {

        this.elements = {
            targetElement: $($targetEl), 
            classNameEl: classNameEl
        }

        this.events()
    }

    events() {
        this.elements.targetElement.parent().addClass(this.elements.classNameEl);
    }
}

export default ChildContainsClass