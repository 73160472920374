import $ from 'jquery'

class SelectAttr {
    constructor($targetEl, targetValue) {

        this.elements = {
            targetElement:  $($targetEl),
            targetValue:  targetValue
        }

        this.events()
    }

    events() {
        this.elements.targetElement.html(this.elements.targetValue);
    }
}

export default SelectAttr