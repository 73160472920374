import $ from 'jquery'

class ToggleMethods {
    constructor(toggleActivator, toggleSelector, toggleClassActivator) {
        this.elements = {
            toggleActivator: toggleActivator,
            toggleSelector: $(toggleSelector),
            toggleClassActivator: toggleClassActivator
        }

        this.events()
    }

    events() {
        $(document).on('click', this.elements.toggleActivator, this.toggleInit.bind(this))
    }

    toggleInit() {
        this.elements.toggleSelector.toggleClass(this.elements.toggleClassActivator)
    }
}

export default ToggleMethods