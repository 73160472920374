import $ from 'jquery'

class HeaderMenu {
    constructor() {
        $('.c-page-header--menu > .menu > .menu__item--has-children > .sub-menu > .menu__item').hover(function() {
            
            var menuItemId = $(this).attr('id');
            var relatedSidebarImageEl = $('.c-page-header--hover-state.' + menuItemId);

            $('.c-page-header--hover-state').removeClass('is-active');

            if (relatedSidebarImageEl.length) {
                relatedSidebarImageEl.addClass('is-active');
            } else {
                $('.c-page-header--hover-state--default').addClass('is-active');
            }
        });

    }
}

export default HeaderMenu