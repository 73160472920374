import $ from 'jquery'

class SplashScreen {
    constructor() {
        var bodyEl = $('body');

        if (bodyEl.hasClass('body--home')) {
            if (Cookies.get('loSplash')) {
                bodyEl.addClass('splash-has-shown');
            } else {
                $('body').addClass('splash-is-visible');

                setTimeout(function() {
                    $('body').removeClass('splash-is-visible');
                    $('body').addClass('splash-has-shown');
    
                    Cookies.set('loSplash', true);
                }, 500);
            }
        } else {
            bodyEl.addClass('splash-has-shown');
        }
    }
}

export default SplashScreen