import $ from 'jquery'

class Offcanvas {
    constructor() {

        this.elements = {
            menuItemWithChildren: $('.menu__item--has-children > a'),
            menuItemWithChildrenFirstLevel: $('.menu > .menu__item--has-children > a'),
            menuItemWithChildrenSecondLevel: $('.menu > .menu__item--has-children > .sub-menu > .menu__item--has-children > a'),
            isActive: 'is-active'
        }

        this.elements.menuItemWithChildrenFirstLevel.on('click', (e) => this.toggleSubMenuFirstLevel(e))
        this.elements.menuItemWithChildrenSecondLevel.on('click', (e) => this.toggleSubMenuSecondLevel(e))

    }

    toggleSubMenuFirstLevel(event) {
        event.preventDefault()
        let itemEl = $(event.currentTarget)

        this.elements.menuItemWithChildren.not(itemEl).parent().removeClass(this.elements.isActive)
        window.setTimeout(() =>  itemEl.parent().toggleClass(this.elements.isActive), 50)
    }

    toggleSubMenuSecondLevel(event) {
        let itemEl = $(event.currentTarget)

        if ($('html').hasClass('touchevents')) {
            event.preventDefault()
        }

        this.elements.menuItemWithChildrenSecondLevel.not(itemEl).parent().removeClass(this.elements.isActive)
        itemEl.parent().toggleClass(this.elements.isActive)
    }
}

export default Offcanvas
