import $ from 'jquery'

class InputAttr {
    constructor($targetEl, attrType, targetValue) {

        this.elements = {
            targetElement: $($targetEl),
            attrType: attrType,
            targetValue: targetValue
        }

        this.events()
    }

    events() {
        this.elements.targetElement.attr(this.elements.attrType, this.elements.targetValue);
    }
}

export default InputAttr