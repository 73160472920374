import $ from 'jquery'

class MenuHoverState {
    constructor() {

        this.elements = {
            targetElement:  $('.c-page-header--menu > .menu > .menu__item--has-children')
        }

        this.events()
    }

    events() {
        this.elements.targetElement.hover(
        function(){ 
            $(this).addClass('is-focused');
            $(this).parents(".c-page-header--menu").addClass('is-focused');
            window.setTimeout(() => $('html').addClass('menu-is-focused'), 2000)
            $(".c-page-header--sidebar").addClass('is-focused');
        }, 
        function(){ 
            $(this).removeClass('is-focused');
            $(this).parents(".c-page-header--menu").removeClass('is-focused');
            window.setTimeout(() => $('html').removeClass('menu-is-focused'), 2000)
            $(".c-page-header--sidebar").removeClass('is-focused');
        })
    }
}

export default MenuHoverState
